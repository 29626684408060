import React, { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { getCookie, setCookie } from 'cookies-next'
import classnames from 'classnames'
import io from '@/utils/io'

import { useAppDispatch } from '@/store/hooks'
import { setIsLogin } from '@/store/global'
import { getCookieTopLevelDomain, storagePrefix } from '@/utils'

import ic_edit from '../../images/ic_edit.png'
import ic_contract from '../../images/ic_contract.png'
import ic_exit from '../../images/ic_exit.png'
import styles from './index.module.scss'

const i18ns = 'user/home'

type MenuProps = {
  className?: string
}

const Menu: FC<MenuProps> = ({ className }) => {
  const { t } = useTranslation(i18ns)
  const router = useRouter()
  const dispatch = useAppDispatch()

  useEffect(() => {
    router.prefetch('/user/profile')
    router.prefetch('/user/contract')
  }, [router])

  const gotoProfile = () => {
    router.push('/user/profile')
  }

  const gotoContract = () => {
    router.push('/user/contract')
  }

  const exit = async () => {
    try {
      await io.put('/v1/logout')
      const token = getCookie(storagePrefix('token'))
      const expires = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30)
      setCookie(storagePrefix('token'), token, {
        expires,
        path: '/',
        domain: getCookieTopLevelDomain(window.location.host)
      })

      dispatch(setIsLogin(false))
    } catch (e) {
      console.log('e', e)
    }
  }

  return (
    <div
      className={classnames(styles.menu, {
        // @ts-ignore
        [className]: className
      })}
    >
      <div className={styles.item} onClick={gotoProfile}>
        <img className={styles.icon} src={ic_edit.src} alt="" />
        <div className={styles.text}>{t('个人页')}</div>
      </div>
      <div className={styles.item} onClick={gotoContract}>
        <img className={styles.icon} src={ic_contract.src} alt="" />
        <div className={styles.text}>{t('合同')}</div>
      </div>
      <div className={styles.item} onClick={exit}>
        <img className={styles.icon} src={ic_exit.src} alt="" />
        <div className={styles.text}>{t('退出')}</div>
      </div>
    </div>
  )
}

export default Menu
