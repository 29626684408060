import React, { FC } from 'react'

import ic_arrow_right from './images/ic_arrow_right.png'
import styles from './index.module.scss'

type ItemProps = {
  icon: string
  title: string
  desc?: string
  badge?: number | string
  onClick?: any
}

const Item: FC<ItemProps> = ({ icon, title, desc, badge, onClick }) => {
  return (
    <div className={styles.item} onClick={onClick}>
      <div className={styles.group}>
        <img className={styles.icon} src={icon} alt="" />
        <div className={styles.text}>
          <div className={styles.title}>{title}</div>
          {desc ? <div className={styles.desc}>{desc}</div> : null}
        </div>
        {badge !== undefined ? <div className={styles.badge}>{badge}</div> : null}
        <img className={styles.arrow} src={ic_arrow_right.src} alt="" />
      </div>
    </div>
  )
}

export default Item
