import React, { FC, PropsWithChildren, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { CenterPopup } from 'antd-mobile'

import styles from './index.module.scss'

type HandleFunc = (() => void) | (() => Promise<any>)

type ModalProps = PropsWithChildren<{
  show: boolean
  oKText?: string
  cancelText?: string
  onOk?: HandleFunc
  onCancel?: HandleFunc
  onClose?: HandleFunc
  closeOnMaskClick?: boolean
  onShow?: () => void
}>

const Modal: FC<ModalProps> = ({
  show,
  children,
  onCancel,
  onOk,
  oKText,
  cancelText,
  onClose,
  onShow,
  closeOnMaskClick = false
}) => {
  const { t } = useTranslation('common')

  useEffect(() => {
    show && onShow?.()
  }, [onShow, show])

  return (
    <CenterPopup visible={show} closeOnMaskClick={closeOnMaskClick} className={styles.modal} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.content}>{children}</div>
        {onCancel || onOk ? (
          <div className={styles.footer}>
            {onCancel && (
              <div className={styles.cancel} onClick={onCancel}>
                {cancelText || t('取消')}
              </div>
            )}
            {onOk && (
              <div className={styles.ok} onClick={onOk}>
                {oKText || t('确认')}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </CenterPopup>
  )
}

export default Modal
