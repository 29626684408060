import React, { FC, useState, useEffect, useRef } from 'react'
import { Toast } from 'antd-mobile'
import Uppy from '@uppy/core'
import UppyAwsS3 from '@uppy/aws-s3'
import io from '@/utils/io'

import styles from './index.module.scss'

type Props = {
  file: File | null
  loading: boolean
  onSucess?: (file: File, uploadURL: string) => void
  onError?: (file: File, error: Error) => void
  onProgress?: (file: File, progress: number) => void
  onComplete?: () => void
}

const UploadVideo: FC<Props> = ({ file, loading, onSucess, onError, onProgress, onComplete }) => {
  const circleRef = useRef(null)
  const uppyRef = useRef<any>(null)
  const [process, setProcess] = useState(0)

  useEffect(() => {
    try {
      uppyRef.current = new Uppy({
        restrictions: {
          allowedFileTypes: null,
          maxNumberOfFiles: null,
          minNumberOfFiles: null
        },
        autoProceed: true,
        infoTimeout: 1000 * 60 * 2
      })
      uppyRef.current.on('upload-progress', onUploadProgress)
      uppyRef.current.on('upload-success', onUploadSuceess)
      uppyRef.current.on('upload-error', onUploadError)
      uppyRef.current.on('complete', onUploadComplete)
    } catch (error) {}

    return () => {
      uppyRef.current.off('upload-progress', onUploadProgress)
      uppyRef.current.off('upload-success', onUploadSuceess)
      uppyRef.current.off('upload-error', onUploadError)
      uppyRef.current.off('complete', onUploadComplete)
    }
  }, [])

  useEffect(() => {
    if (file) {
      uploadVideoToS3(file)
    }
  }, [file])

  const onUploadProgress = (file: any, progress: any) => {
    const { bytesUploaded, bytesTotal } = progress
    const percent = Math.floor((bytesUploaded / bytesTotal) * 100)

    if (percent !== 100) {
      animate(percent)
      setProcess(percent)
    }

    onProgress && onProgress(file, progress)
  }

  const onUploadSuceess = (file: any, { uploadURL }: any) => {
    onSucess && onSucess(file, uploadURL)
  }

  const onUploadError = (file: any, error: any) => {
    Toast.show({
      content: error,
      duration: 3000
    })
    onError && onError(file, error)
  }

  const onUploadComplete = () => {
    onComplete && onComplete()
  }

  const animate = (percent: number) => {
    const circle = circleRef.current as any
    const circumference = 2 * Math.PI * 16
    const offset = circumference - (percent / 100) * circumference
    circle.style.transition = 'stroke-dashoffset 200ms linear'
    circle.style.strokeDashoffset = offset
  }

  const uploadVideoToS3 = async (file: any) => {
    const { name, type } = file
    try {
      uppyRef.current.use(UppyAwsS3, {
        getUploadParameters: async () => {
          const { data } = await io.post('/presigned', {
            type,
            filename: name
          })
          return data
        }
      })

      uppyRef.current.addFile({
        id: 'FileUpload',
        data: file,
        name: name,
        type
      })
    } catch (error) {}
  }

  return (
    <div className={`${styles.mask} ${loading ? styles.open : ''}`}>
      <svg width="40" height="40">
        <circle
          cx="20"
          cy="20"
          r="16"
          stroke="rgba(255,255,255,0.16)"
          strokeWidth="3"
          fill="none"
          strokeLinecap="round"
        />
        <circle
          ref={circleRef}
          cx="20"
          cy="20"
          r="16"
          stroke="#FFCD22"
          strokeWidth="4"
          strokeLinecap="round"
          fill="none"
          strokeDasharray="100.531"
          strokeDashoffset="100.531"
          transform="rotate(-90 20 20)"
        />
      </svg>
      <div className={styles.processLabel}>{process}%</div>
    </div>
  )
}

export default UploadVideo
