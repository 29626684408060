import React, { FC, useEffect, useState, useRef, ChangeEventHandler } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import useRequest from '@ahooksjs/use-request'
import { Mask, Toast } from 'antd-mobile'
import Modal from '@/components/Modal'
import UploadVideo from '@/components/UploadVideo'

import ic_add from './images/ic_add.png'
import ic_close from './images/ic_close.png'
import ic_video_filled from './images/ic_video_filled.png'
import ic_script_filled from './images/ic_script_filled.png'
import styles from './index.module.scss'

const i18ns = 'user/home'

type FloatingBtnProps = {
  className?: string
}

type FaildReason = '视频尺寸' | '视频时长' | '视频大小'

const FloatingBtn: FC<FloatingBtnProps> = () => {
  const { t } = useTranslation(i18ns)
  const router = useRouter()
  const inputRef = useRef(null)
  const videoFileRef = useRef<{ id: string; url: string }>({ id: '', url: '' })
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState<'guide' | 'faild'>('faild')
  const [faildReason, setFaildReason] = useState<FaildReason>('视频尺寸')
  const [videoFile, setVideoFile] = useState<File | null>(null)

  const { run: getVideoMeta } = useRequest(
    videoId => {
      return {
        url: 'ug-framote/video/meta',
        params: {
          videoId
        }
      }
    },
    {
      manual: true,
      formatResult: res => {
        return res
      },
      onSuccess: res => {
        return res
      },
      onError: err => {
        handleReset()

        // @ts-ignore
        const code = err.code
        if ([20401, 20402, 20403].indexOf(code) === -1) {
          Toast.show({
            content: err.message,
            duration: 3000
          })
          return
        }
        const faildReason = {
          20401: '视频时长',
          20402: '视频尺寸',
          20403: '视频大小'
        }[code as string] as FaildReason
        setFaildReason(faildReason)
        setModalType('faild')
        setModalVisible(true)
      }
    }
  )

  useEffect(() => {
    router.prefetch('/user/script/apply')
  }, [router])

  const onUploadSuceess = (file: any, uploadURL: any) => {
    const { key } = file.meta
    const id = String(key).replace(/new\//, '')
    videoFileRef.current = {
      id,
      url: uploadURL
    }
  }

  const onComplete = async () => {
    if (!videoFileRef.current.id) {
      handleReset()
      Toast.show({
        content: t('网络错误，上传失败'),
        duration: 3000
      })
      return
    }

    const videoMeta = await getVideoMeta(videoFileRef.current.id)
    setLoading(false)
    if (videoMeta.code !== 0) {
      return
    }
    router.push({
      pathname: '/user/video/apply',
      query: { videoId: videoFileRef.current.id }
    })
  }

  const toggleVisible = () => {
    setVisible(val => !val)
  }

  const loadVideo = function (file: Blob | MediaSource) {
    return new Promise(function (resolve, reject) {
      const videoElem = document.createElement('video')
      const dataUrl = URL.createObjectURL(file)

      const loadHandler = function () {
        videoElem.removeEventListener('loadedmetadata', loadHandler)
        resolve(videoElem)
      }

      videoElem.addEventListener('loadedmetadata', loadHandler)

      videoElem.onerror = function () {
        reject('video 后台加载失败')
      }

      videoElem.setAttribute('preload', 'auto')
      videoElem.setAttribute('playsinline', '') // 添加 playsinline 属性，以在 iOS 中自动播放视频
      videoElem.src = dataUrl
      videoElem.load()
    })
  }

  const handleReset = () => {
    videoFileRef.current = {
      id: '',
      url: ''
    }
    setLoading(false)
    handleInputFile(false)
    setVideoFile(null)
  }

  const handleUploadVideo = () => {
    // 前3次弹出引导
    const guideCount = Number(localStorage.getItem('Guide_Count')) || 0
    if (guideCount < 3) {
      setModalType('guide')
      setModalVisible(true)
      return
    }
    handleInputFile(true)
  }

  const handleInputFile = (isSelect?: boolean) => {
    if (!inputRef.current) return
    const target = inputRef.current as HTMLButtonElement
    if (isSelect) {
      target.click()
      return
    } else {
      // 重置input值，避免选择相同文件不触发change事件
      target.value = ''
    }
  }

  const handleCheckVideo = (width: number, height: number, duration: number, size: number) => {
    if (width > 1080 || height > 1920) {
      setFaildReason('视频尺寸')
      setModalType('faild')
      setModalVisible(true)
      return false
    }
    if (duration < 19 || duration > 41) {
      setFaildReason('视频时长')
      setModalType('faild')
      setModalVisible(true)
      return false
    }
    if (size < 5 * 1024 * 1024 || size > 150 * 1024 * 1024) {
      setFaildReason('视频大小')
      setModalType('faild')
      setModalVisible(true)
      return false
    }
    return true
  }

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async e => {
    const target = e.target as HTMLInputElement
    const files = target.files as unknown as any[]
    const f = files[0]
    console.log('e', f)
    const v = (await loadVideo(f)) as any
    console.log('v', v)
    // 时长
    const duration = v.duration
    console.log('🚀 ~ file: index.tsx ~ line 53 ~ duration', duration)
    // 宽
    const width = v.videoWidth
    // 高
    const height = v.videoHeight
    console.log('尺寸：', `${width}*${height}`)
    console.log('时长：', duration)
    console.log('大小：', f.size)
    toggleVisible()
    const isValidate = handleCheckVideo(width, height, duration, f.size)
    if (isValidate) {
      setLoading(true)
      setVideoFile(f)
    } else {
      handleInputFile(false)
    }
    console.log('isValidate', isValidate)
  }

  const gotoScriptApply = () => {
    router.push('/user/script/apply')
  }

  const getModalContent = (type: 'guide' | 'faild') => {
    const content = {
      guide: t('视频上传提醒'),
      faild: (
        <>
          <div className="modal-title">{t('上传失败')}</div>
          <div className="modal-text">
            {t('上传失败原因x（理由）', {
              x: `"${t(faildReason)}"`
            })}
          </div>
        </>
      )
    }
    return content[type]
  }

  const handleModalConfirm = () => {
    if (modalType === 'guide') {
      // 使用本地缓存记录引导次数
      const guideCount = Number(localStorage.getItem('Guide_Count')) || 0
      localStorage.setItem('Guide_Count', String(guideCount + 1))
      handleInputFile(true)
    }
    setModalVisible(false)
  }

  return (
    <div className={styles.floatingBtn}>
      <input
        ref={inputRef}
        className={styles.uploadBtn}
        type="file"
        onChange={handleFileChange}
        multiple
        accept="video/*"
        capture={false}
      />
      {!visible ? (
        <div className={styles.addBtn} onClick={toggleVisible}>
          <img className={styles.icon} src={ic_add.src} alt="" />
        </div>
      ) : null}
      <Mask visible={visible} onMaskClick={toggleVisible}>
        <div className={styles.overlayContent}>
          <div className={styles.item} onClick={handleUploadVideo}>
            <div className={styles.text}>{t('视频')}</div>
            <div className={styles.btn}>
              <img className={styles.icon} src={ic_video_filled.src} alt="" />
            </div>
          </div>
          <div className={styles.item} onClick={gotoScriptApply}>
            <div className={styles.text}>{t('脚本')}</div>
            <div className={styles.btn}>
              <img className={styles.icon} src={ic_script_filled.src} alt="" />
            </div>
          </div>
          <div className={styles.item} onClick={toggleVisible}>
            <div className={styles.closeBtn}>
              <img className={styles.icon} src={ic_close.src} alt="" />
            </div>
          </div>
        </div>
      </Mask>
      <Modal show={modalVisible} oKText={t('确认')} onOk={handleModalConfirm}>
        {getModalContent(modalType)}
      </Modal>
      {videoFile && (
        <UploadVideo
          file={videoFile}
          loading={loading}
          onSucess={(file, uploadURL) => onUploadSuceess(file, uploadURL)}
          onComplete={onComplete}
          onError={() => {
            Toast.show({
              content: t('网络错误，上传失败'),
              duration: 3000
            })
            handleReset()
          }}
        />
      )}
    </div>
  )
}

export default FloatingBtn
