import React, { useEffect, useRef, useState } from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import useRequest from '@ahooksjs/use-request'
import { useAppSelector } from '@/store/hooks'
import { selectGlobal } from '@/store/global'
import { storagePrefix } from '@/utils'

import Item from '@/pagesFolder/user/home/components/Item'
import Menu from '@/pagesFolder/user/home/components/Menu'
import FloatingBtn from '@/pagesFolder/user/home/components/FloatingBtn'

import ic_caret_up from '@/pagesFolder/user/home/images/ic_caret_up.png'
import ic_caret_down from '@/pagesFolder/user/home/images/ic_caret_down.png'
import ic_feedback from '@/pagesFolder/user/home/images/ic_feedback.png'
import ic_video from '@/pagesFolder/user/home/images/ic_video.png'
import ic_script from '@/pagesFolder/user/home/images/ic_script.png'
import ic_wallet from '@/pagesFolder/user/home/images/ic_wallet.png'
import ic_revenue from '@/pagesFolder/user/home/images/ic_revenue.png'
import ic_tiktok from '@/pagesFolder/user/home/images/ic_tiktok.png'
import ic_coming_soon from '@/pagesFolder/user/home/images/ic_coming_soon.png'
import styles from './index.module.scss'

const i18ns = 'user/home'

const Home: NextPage = () => {
  const { t } = useTranslation(i18ns)
  const router = useRouter()
  const { accountInfo } = useAppSelector(selectGlobal)

  const [showMenu, setShowMenu] = useState(false)
  const [videoReminder, setVideoReminder] = useState(0)
  const [scriptReminder, setScriptReminder] = useState(0)
  const leftRef = useRef(null)

  const { run: getVideoReminder } = useRequest(
    () => ({
      url: '/ug-framote/content/remind-total',
      params: {
        contentType: 'video'
      }
    }),
    {
      manual: true,
      formatResult: res => {
        const { onlineTotal, reviewTotal } = res.data
        return onlineTotal + reviewTotal
      },
      onSuccess: data => {
        setVideoReminder(data)
      }
    }
  )

  const { run: getScriptReminder } = useRequest(
    () => ({
      url: '/ug-framote/content/remind-total',
      params: {
        contentType: 'script'
      }
    }),
    {
      manual: true,
      formatResult: res => {
        const { onlineTotal, reviewTotal } = res.data
        return onlineTotal + reviewTotal
      },
      onSuccess: data => {
        setScriptReminder(data)
      }
    }
  )

  useEffect(() => {
    localStorage[storagePrefix('hasLogin')] = 1
    getScriptReminder()
    getVideoReminder()
  }, [])

  useEffect(() => {
    document.addEventListener('click', onMenuClose, false)
    return () => {
      document.removeEventListener('click', onMenuClose, false)
    }
  }, [showMenu])

  useEffect(() => {
    router.prefetch('/user/video')
    router.prefetch('/user/script')
    router.prefetch('/user/tt-promotion')
    router.prefetch('/user/wallet')
    router.prefetch('/user/revenue-details')
  }, [router])

  const onMenuClose = (e: DocumentEventMap['click']) => {
    // @ts-ignore
    if (e.target === leftRef.current || e.target.parentElement === leftRef.current) return
    setShowMenu(false)
  }

  const gotoFeedback = () => {
    window.open('https://forms.gle/K7xwC9egQtyGQRAc9')
  }

  const gotoVideo = () => {
    router.push('/user/video')
  }

  const gotoScript = () => {
    router.push('/user/script')
  }

  const gotoTTPromotion = () => {
    router.push('/user/tt-promotion')
  }

  const gotoWallet = () => {
    router.push('/user/wallet')
  }

  const gotoRevenueDetails = () => {
    router.push('/user/revenue-details')
  }

  return (
    <div className={styles.home}>
      <div className={styles.contentWrapper}>
        <div className={styles.baseNavBar}>
          <div className={styles.left} ref={leftRef} onClick={() => setShowMenu(true)}>
            <img className={styles.avatar} src={accountInfo.avatar} alt="" />
            <div className={styles.title}>
              {`${accountInfo.firstName} ${accountInfo.familyName}`.length > 15
                ? `${accountInfo.firstName} ${accountInfo.familyName}`.substring(0, 15) + '...'
                : `${accountInfo.firstName} ${accountInfo.familyName}`}
            </div>
            <img className={styles.arrow} src={showMenu ? ic_caret_up.src : ic_caret_down.src} alt="" />
            {showMenu ? <Menu /> : null}
          </div>
          <img className={styles.right} src={ic_feedback.src} alt="" onClick={gotoFeedback} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{t('管理')}</div>
          <Item
            icon={ic_video.src}
            title={t('视频管理')}
            desc={t('查看状态并完善制作')}
            badge={videoReminder || undefined}
            onClick={gotoVideo}
          />
          <Item icon={ic_script.src} title={t('脚本管理')} badge={scriptReminder || undefined} onClick={gotoScript} />

          <div className={styles.title}>{t('收益')}</div>
          <Item icon={ic_wallet.src} title={t('钱包')} desc={t('在这里提现')} onClick={gotoWallet} />
          <Item icon={ic_revenue.src} title={t('收益明细')} onClick={gotoRevenueDetails} />

          <div className={styles.title}>{t('推广任务')}</div>
          <Item icon={ic_tiktok.src} title={t('在TikTok推广')} onClick={gotoTTPromotion} />
          <Item icon={ic_coming_soon.src} title={t('随处推广')} desc={t('即将推出')} />
        </div>
        {/* float btn */}
        <FloatingBtn />
      </div>
    </div>
  )
}

Home.getInitialProps = () => {
  return { ns: i18ns }
}

export default Home
